/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListRecentlyReservedSerialNumbersResponse } from '../models/ListRecentlyReservedSerialNumbersResponse';
import type { ReservedSerialnumbersExportXlsxResponse } from '../models/ReservedSerialnumbersExportXlsxResponse';
import type { SerialNumberReservationsCreateRequest } from '../models/SerialNumberReservationsCreateRequest';
import type { SerialNumberReservationsCreateResponse } from '../models/SerialNumberReservationsCreateResponse';
import type { SerialNumberReservationsDetailsResponse } from '../models/SerialNumberReservationsDetailsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SerialNumberReservationsService {

    /**
     * @param requestBody 
     * @returns SerialNumberReservationsCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1SerialNumberReservations(
requestBody?: SerialNumberReservationsCreateRequest,
): CancelablePromise<SerialNumberReservationsCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/SerialNumberReservations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param reservationId 
     * @returns SerialNumberReservationsDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1SerialNumberReservations(
reservationId: number,
): CancelablePromise<SerialNumberReservationsDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/SerialNumberReservations/{reservationId}',
            path: {
                'reservationId': reservationId,
            },
        });
    }

    /**
     * @param reservationId 
     * @returns ReservedSerialnumbersExportXlsxResponse Success
     * @throws ApiError
     */
    public static getApiV1SerialNumberReservationsExportXlsx(
reservationId: number,
): CancelablePromise<ReservedSerialnumbersExportXlsxResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/SerialNumberReservations/{reservationId}/export/xlsx',
            path: {
                'reservationId': reservationId,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param fromDate 
     * @param toDate 
     * @returns ListRecentlyReservedSerialNumbersResponse Success
     * @throws ApiError
     */
    public static getApiV1SerialNumberReservationsListRecentlyReserved(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
fromDate?: string,
toDate?: string,
): CancelablePromise<ListRecentlyReservedSerialNumbersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/SerialNumberReservations/ListRecentlyReserved',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
                'FromDate': fromDate,
                'ToDate': toDate,
            },
        });
    }

}
