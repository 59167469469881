import {
  createWebHistory,
  createRouter,
} from "vue-router";
import { addAuthToGuards } from "./global-guards";
import { type AuthenticationService } from "@congatec/authentication-lib";
import { type Ref, ref } from "vue";
import { HomeView } from "@/components";
import { SerialNumberReservationDashboardView } from "@/components/serial-number-reservations";
import { ProductionOrdersCheckoutView } from "@/components/production-orders";
import { ConSso } from '@congatec/primevue-components-lib';
import { SettingsList } from "@/components/settings";

const _authenticationService: Ref<AuthenticationService | undefined | null> =
  ref(null);

export function addAuthToRouter(authenticationService: AuthenticationService) {
  _authenticationService.value = authenticationService;
  addAuthToGuards(_authenticationService.value);
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      breadcrumb: [
        { label: "" }
      ]
    }
  },
  {
    path: '/silent-sso',
    name: 'silent-sso',
    component: ConSso
  },
  // {
  //   path: "/",
  //   name: "Dashboard",
  //   component: DashboardContent,
  // },
  {
    path: "/production-orders/pdfs/download",
    name: "ProductionOrdersCheckout",
    component: ProductionOrdersCheckoutView,
    meta: {
      breadcrumb: [
        { label: "Production Orders Download" }
      ]
    }
  },
  // {
  //   path: "/:catchAll(.*)",
  //   name: "home",
  //   component: HomeView,
  // },
  {
    path: "/serial-number-reservation",
    name: "SerialNumberReservation",
    component: SerialNumberReservationDashboardView,
    meta: {
      breadcrumb: [
        { label: "Serial Number Reservation" }
      ]
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsList,
    meta: {
      breadcrumb: [
        { label: "Settings" }
      ]
    }
  },
];

const router = createRouter({ history: createWebHistory(), routes });

export default router;
