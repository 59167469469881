<template>
  <!-- <Card>
    <template #content> -->
      <ConDetails
        :contentItems="contentItems"
        title=""
        subTitle="Serial Number Reservation"
      >
      <template #item="{ content }">
          <div v-for="c in content" :key="c">
            <div class="flex justify-content-between">
              <div>
                <span>{{c.title}}:</span>
              </div>
            <div>
              {{c.content}}
            </div>
          </div>
          <Divider />
          </div>
      </template>
      <template #footer>
      </template>
    </ConDetails>
    <SerialNumberReservationSerialNumbersListView
        :propReservationId="propReservationId"
      >
      </SerialNumberReservationSerialNumbersListView>
    <!-- </template>
  </Card> -->
</template>

<script setup lang="ts">
import { defineComponent, onMounted, type Ref, ref, toRef, watch } from "vue";
import {
  type SerialNumberReservationsDetailsResponse,
  SerialNumberReservationsService,
} from "@/shared/services/athene-api";
import { SerialNumberReservationSerialNumbersListView } from '@/components/serial-number-reservations';
import Divider from "primevue/divider";
import { ConDetails } from '@congatec/primevue-components-lib';

const props = defineProps({
  propReservationId: {
    default: (): any => 0
  },
  propShowSubHeader: {
    type: Boolean,
    default: true,
  },
});

const isLoading = ref(true);
const serialNumberReservation: Ref<SerialNumberReservationsDetailsResponse | null> =
  ref(null);
const propReservationIdRef = toRef(props, 'propReservationId');
let contentItems: Ref<any> = ref();

onMounted(() => {
  if (serialNumberReservation.value == null) {
    _fetchDataFromService();
  }
});

// watch(propReservationIdRef, async (oldValue, newValue) => {
//   SerialNumberReservationsService.getApiV1SerialNumberReservations(
//     Number(newValue)
//   )
//     .then((response) => {
//       if (response) {
//         serialNumberResevation.value = response;
//       }
//       isLoading.value = false;
//     })
//     .catch((error) => {
//       isLoading.value = false;
//     });
// });

watch(propReservationIdRef, _fetchDataFromService);

async function _fetchDataFromService() {
  console.log("SerialNumberReservationsDetails _fetchDataFromService: propReservationId", propReservationIdRef.value);
  SerialNumberReservationsService.getApiV1SerialNumberReservations(
    Number(propReservationIdRef.value)
  )
    .then((response) => {
      if (response) {
        serialNumberReservation.value = response;
      }
      isLoading.value = false;

      contentItems.value = [
        {
          title: "Details",
          content: [
            {
              title: "ID",
              content: serialNumberReservation.value?.id?.toString(),
            },
            {
              title: "Amount",
              content: serialNumberReservation.value?.amount?.toString(),
            },
            {
              title: "Description",
              content: serialNumberReservation.value?.description,
            },
            {
              title: "Start Serialnumber",
              content:
                serialNumberReservation.value?.startSerialNumber?.toString(),
            },
            {
              title: "End Serialnumber",
              content:
                serialNumberReservation.value?.endSerialNumber?.toString(),
            },
            {
              title: "Pool Id",
              content: serialNumberReservation.value?.poolId?.toString(),
            },
            // {
            //   title: "Serialnumbers",
            //   content: serialNumberReservation.value?.serialNumbers,
            // },
            
          ],
        },
      ];
    })
    .catch((error) => {
      isLoading.value = false;
    });
}
</script>

<script lang="ts">
export default defineComponent({
  name: "SerialNumberReservationDetailsView",
});
</script>
<style lang="css"> 

.details-label {
  text-decoration: underline;

}

</style>