<template>
<div v-if="propReservationId">
    <Button label="Export as Excel" @click="downloadFile" icon="pi pi-file-excel" />
    <!-- <v-btn text @click="downloadFile" class="pr-0 pl-0">
    {{propTitle != null ? propTitle : ""}}
    <v-icon class="pl-2">get_app</v-icon>
    </v-btn> -->
</div>
</template>
<script setup lang="ts">
import { defineComponent, onMounted, toRef } from "vue";
import { SerialNumberReservationsService } from "@/shared/services/athene-api";
import Button from "primevue/button";
import { useFileDownloadStatusStore, FileStatusStoreStatusType } from "@congatec/primevue-components-lib";

const props = defineProps({
    propReservationId: {
        default: (): any => 0
    }
});

const propReservationIdRef = toRef(props, 'propReservationId');
// let ready = true;
const store = useFileDownloadStatusStore();


let _content: any = null;
let _fileName = '';
const _mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

onMounted(() => {
    if (propReservationIdRef.value) {
        _fileName = `serial-number-reservation-${propReservationIdRef.value}-export.xlsx`;
        // ready = true;
    }
})

async function fetchFileContent(id: number): Promise<any> {
    const response = await SerialNumberReservationsService.getApiV1SerialNumberReservationsExportXlsx(id);
    if (response.workbook != null) {
        _content = response.workbook;
        return response.workbook;
    } else {
        return null;
    }
}

async function downloadFile(): Promise<void> {
    if (propReservationIdRef.value) {
        if (!_content) {
            emit("xlsx-export-started");

            store.add({
                id: propReservationIdRef.value.toString(),
                name: _fileName,
                url: "",
                status: FileStatusStoreStatusType.DOWNLOADING,
            });

            fetchFileContent(propReservationIdRef.value).then(
            (data) => {
                if (data) {
                    forceFileDownload(propReservationIdRef.value.toString(), Uint8Array.from(atob(data),(c) => c.charCodeAt(0)), _fileName);
                }
            },
            (error) => {
                emit("xlsx-export-failed");
                store.update({
                    id: propReservationIdRef.value.toString(),
                        changes: {
                        url: "",
                        status: FileStatusStoreStatusType.ERROR,
                        },
                });
            }
            );
        }
    }
}

function forceFileDownload(id: string, data: Uint8Array, name: string): void {
    if (data) {
        const url = window.URL.createObjectURL(
            new Blob([data], { type: _mimeType })
        );

        store.update({
            id: id,
            changes: {
            url: url,
            status: FileStatusStoreStatusType.DOWNLOADED,
            },
        });

        // const link = document.createElement("a");
        // link.href = url;
        // link.download = name;
        // link.click();
    }
}

const emit =
  defineEmits<{
    (event:"xlsx-export-started"): void;
    (event:"xlsx-export-failed"): void;
  }>();

</script>
<script lang="ts">
export default defineComponent({
    name: "SerialNumberReservationExportXlsxView",
});

</script>