<template>
  <div>
    <DynamicDialog />
    <ConfirmDialog />
    
    <div class="flex align-items-stretch" >
      <div class="flex flex-column" style="width: 50%;">
        <SerialNumberPoolsListView :key="SerialNumberPoolsListKey" :propPoolId="selectedPool?.id" style="width: 100%; padding-right: 8px; padding-bottom: 8px;"
          @serialNumberPoolsListSelectionChanged="serialNumberPoolSelectionChanged" @editClosed="forceSerialNumberPoolsDetailsRerender"
        >
        </SerialNumberPoolsListView>
        <SerialNumberPoolsDetailsView :key="SerialNumberPoolsDetailsKey" :propPool="selectedPool" style="width: 100%; padding-right: 8px; padding-bottom: 8px;">
          
        </SerialNumberPoolsDetailsView>
      </div>
      <div class="flex flex-column" style="width: 50%;">
        <SerialNumberReservationCreateView style="width: 100%; padding-bottom: 8px;"
            :propPool="selectedPool"
            @SerialNumberReservationCreateSuccess="serialNumberReservationCreated"
          >
        </SerialNumberReservationCreateView>
        <SerialNumberReservationDetailsView
            v-if="reservationId"
            :propReservationId="reservationId"
            >
          </SerialNumberReservationDetailsView>
      </div>
      
    </div>
  </div>
</template>
<script setup lang="ts">
import { SerialNumberPoolsListView, SerialNumberPoolsDetailsView } from '@/components/serial-number-pools';
import { SerialNumberReservationCreateView, SerialNumberReservationDetailsView } from '@/components/serial-number-reservations';
import { type Ref, defineComponent, ref } from 'vue';
import ConfirmDialog from 'primevue/confirmdialog';
import DynamicDialog from 'primevue/dynamicdialog';

const SerialNumberPoolsListKey = ref(0);
const SerialNumberPoolsDetailsKey = ref(0)
const selectedPool: Ref<any | undefined> = ref();
const reservationId: Ref<number | null> = ref(null);

const forceSerialNumberPoolsListRerender = () => {
  SerialNumberPoolsListKey.value += 1;
}

const forceSerialNumberPoolsDetailsRerender = () => {
  SerialNumberPoolsDetailsKey.value +=1;
}

function serialNumberPoolSelectionChanged(event: any) {
  selectedPool.value = event;
  reservationId.value = null;
  console.log("Dashboard serialNumberPoolSelectionChanged", selectedPool.value);
}

function serialNumberReservationCreated(paramReservationId: any) {
  reservationId.value = paramReservationId;
  var oldPool = selectedPool;
  if(oldPool !== null) {
    selectedPool.value = oldPool.value;
  }
  // tell SerialNumberPoolsListView to rerender itself and reload it's data
  forceSerialNumberPoolsListRerender();
  console.log("Dashboard serialNumberReservationCreated", reservationId.value);
}


</script>

<script lang="ts">
export default defineComponent({
  name: "SerialNumberReservationDashboardView",
});
</script>

<style lang="scss" scoped>

</style>